import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SavingsForm from '../components/Sections/SavingsForm'
import { getAssetUrl } from '../services/cdn'
import { Container as BlockContainer, Features } from '../components/Blocks'
import { Hero, BecomeMember } from '../components/Sections'
import { Container, Row, Col } from 'reactstrap'

const IndexPage = () => (
  <Layout>
    <SEO title="Deposits" keywords={[`gatsby`, `application`, `react`]} />
    <Hero src='app/Koopinoy-Deposits.jpg' height={600} x={100} />
    <BlockContainer padding={5}>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">What We Offer?</h1>
              <p>
                “Mind your coin, you may never know when!” <br />
                <i>― Ernest Agyemang Yeboah</i>
              </p>

              <p>KooPinoy accepts time deposit and regular savings deposits. In return, comparably high interest rates were offered to account holders, both regular and associate members. This is to inculcate our members the value and importance of savings. We do also involve the young ones to be part of our growing family in terms of savings through Young Savers Club.  This Young Savers Club aims to impart the essence of savings at the very young age. What are you waiting for? Be a Member and Be one of Us!</p>
              <p>
                "Do not save what is left after spending; instead spend what is left after saving.” <br />
                <i> ― Warren Buffett</i>
              </p>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">KooPinoy Deposits Features</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="3" sm="6">
              <Features iconClass="fa fa-circle fa-3x" title="Regular Deposit">Enjoy the easiest way to grow your money with regular deposit.</Features>
          </Col>
          <Col lg="3" sm="6">
              <Features iconClass="fa fa-stop-circle-o fa-3x" title="Time Deposit">Time is gold. Enjoy high interest rate on your time deposit.</Features>
          </Col>
          <Col lg="3" sm="6">
              <Features iconClass="fa fa-stop-circle fa-3x" title="myFuture Savings Fund">
			  Pool your hard earned money and let us customize your savings plan. Reach your dream with myFuture Savings Plan
			<Col md="12">
				<small><em><font color="green">To customize your own Savings Plan</font></em></small>
				<a className="btn btn-primary btn-sm" href={process.env.GATSBY_CDN + '/documents/koopinoy/myFutureFundSavings Calculator.xlsx'}>Download myFuture Savings Fund Calculator</a>
			</Col>
			  </Features>
          </Col>
          <Col lg="3" sm="6">
              <Features iconClass="fa fa-circle-o fa-3x" title="Young Saver's Club">Teach your kids the values of both cooperation and savings at young age. Register them to Young Cooperators, a Laboratory Cooperative of SIDC and open a Young Savers Club Savings account.</Features>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BlockContainer paddingTop={5} paddingBottom={0} dark id="features">
      <Container>
          <Row>
          <Col md="12">
              <div className="text-center">
              <h2 className="pb-4 text-dark">Benefits for Depositors</h2>
              </div>
          </Col>
          </Row>
          <Row>
          <Col lg="4" md="6" className="text-md-right text-center">
              <h4 className="text-primary mb-5">High Interest on Deposits.</h4>
              <h4 className="text-primary mt-5">Local and International Trip Incentives (Top Depositor and Top Borrower with good payment records)</h4>
          </Col>
          <Col md="4" className="my-3 d-none d-lg-block">
              <img className="img-fluid d-block" alt="KOOPINOY FEATURES" src={getAssetUrl('app/Koopinoy_Logo_Square.jpg')} />
          </Col>
          <Col lg="4" md="6" className="text-md-left text-center">
              <h4 className="text-primary mb-5">Tax-Free on Savings</h4>
              <h4 className="text-primary mb-5">Insured under Cooperative Deposit Insurance System (CODIS) up to Php100,000.00</h4>
          </Col>
          <Col md="12">
            <Container>
              <h4 className="text-primary">With issued passbook or certificate.</h4>
            </Container>
          </Col>
          </Row>
      </Container>
    </BlockContainer>
    {/* <BlockContainer padding={3}>
        <Container>
          <Row>
            <Col md="12">
              <Table className="text-center">
                <thead>
                  <tr>
                    <th></th>
                    <th className="text-center"><h3>Deposit amount</h3></th>
                    <th className="text-center"><h3>Interest</h3></th>
                    <th className="text-center"><h3>Your Wallet</h3></th>
                  </tr>
                  <tr>
                    <th></th>
                    <th className="text-center">Sample amount to deposit</th>
                    <th className="text-center">Sample interest</th>
                    <th className="text-center">Cash out</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>3 month</td>
                    <td>5000</td>
                    <td>2.5%</td>
                    <td>375</td>
                  </tr>
                  <tr>
                    <td>3 month</td>
                    <td>5000</td>
                    <td>2.5%</td>
                    <td>375</td>
                  </tr>
                  <tr>
                    <td>3 month</td>
                    <td>5000</td>
                    <td>2.5%</td>
                    <td>375</td>
                  </tr>
                  <tr>
                    <td>3 month</td>
                    <td>5000</td>
                    <td>2.5%</td>
                    <td>375</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
    </BlockContainer> */}
    {/* <BlockContainer padding={5}
      style={{
        backgroundImage: `url(${getAssetUrl('app/dummy-action.png')})`,
        backgroundSize: "cover",
        backgroundPosition: "top left",
        backgroundRepeat: "repeat"
      }}
    >
      <Container>
        <Row>
          <Col md="6" sm="10" className="mx-auto bg-white p-5">
            <h1>Do not procrastinate. Act</h1>
            <p className="mb-3">Get the right offer for your right concerns. Click below to get started! We are happy to serve.</p>
            <Link className="btn btn-primary" to="/">Act now</Link>
          </Col>
        </Row>
      </Container>
    </BlockContainer> */}
    <SavingsForm/>
    <BlockContainer>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">How to Avail: Apply in three (3) easy steps</h1>
              <p className="lead">KooPinoy makes Savings and Deposits so easy.</p>
          </Col>
        </Row>
        <Row>
          <Col lg="4" sm="12">
              <Features iconClass="" title="1">Be a Member (Associate or Regular Membership)</Features>
          </Col>
          <Col lg="4" sm="12">
              <Features iconClass="" title="2">Fill up the Depositors Personal Data Card, deposit slip and payment</Features>
          </Col>
          <Col lg="4" sm="12">
              <Features iconClass="" title="3">Passbook Insurance</Features>
          </Col>
        </Row>
        <Row>
          <Col md="12">
              <a className="btn btn-primary" href={process.env.GATSBY_SITE_MAIN + '/membership'}>Become a Member</a>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <BecomeMember />
  </Layout>
)

export default IndexPage
